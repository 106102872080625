import { Fragment } from 'react'

import { withGoogleSheets } from 'react-db-google-sheets'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'

import UserIcon from '@mui/icons-material/Person'


const Invitati = (props) => {
  const invitati = props.db[props.dataSource]
  const bgColor = props.bgColor
  const title = props.title
  
  // console.log(invitati)
  
  return (
    <Box
      sx={{
        px: 8,
        py: 12,
        backgroundColor: bgColor || 'transparent',
        // color: 'white',
        textAlign: 'left',
        '& .MuiListItemText-primary': {
          fontWeight: 400,
        },
      }}
    >
      <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h4" align="center" gutterBottom>
            {title || 'Listă'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <List>
            {invitati.map((invitee) => (
              <Fragment key={invitee.name}>
                <Divider sx={{ my: 0.75 }} />
                <ListItem disablePadding>
                  {invitee.photo ? (
                    <ListItemAvatar>
                      <Avatar alt={invitee.name} src={invitee.photo} />
                    </ListItemAvatar>
                  ) : (
                    <ListItemAvatar>
                      <Avatar>
                        <UserIcon />
                      </Avatar>
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={invitee.name}
                    secondary={`
                      ${invitee.facRole ? `${invitee.facRole}, ` : ''}
                      ${invitee.fac ? `${invitee.fac}, ` : ''}
                      ${invitee.uniRole ? `${invitee.uniRole}, ` : ''}
                      ${invitee.uni ? `${invitee.uni}` : ''}
                    `}
                  />
                </ListItem>
              </Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withGoogleSheets()(Invitati)
