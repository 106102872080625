import React from 'react'

import { useTheme } from '@mui/material/styles'

export default function CircularStatic({
  siteName,
  siteLogo,
  siteLogoType,
  siteLogoPosition,
}) {
  const theme = useTheme()

  let LogoContainerIMG, LogoContainerCSS

  const logoStyles = {
    appBar: { marginRight: theme.spacing(2) },
    footer: { marginRight: theme.spacing(2) },
  }

  switch (siteLogoPosition) {
    case 'AppBar':
      LogoContainerCSS = { ...logoStyles.appBar, maxHeight: theme.spacing(6) }
      break
    case 'Footer':
      LogoContainerCSS = { ...logoStyles.footer, maxHeight: theme.spacing(32) }
      break
    default:
      LogoContainerCSS = { maxHeight: theme.spacing(6) }
  }

  switch (siteLogoType) {
    case 'IMG':
      LogoContainerIMG = (
        <img src={siteLogo} alt={siteName} style={LogoContainerCSS} />
      )
      break
    case 'SVG':
      LogoContainerIMG = (
        <img src={siteLogo} alt={siteName} style={LogoContainerCSS} />
      )
      break
    case 'SVGi':
      LogoContainerIMG = 'SVGi'
      break
    default:
      LogoContainerIMG = (
        <img src={siteLogo} alt={siteName} style={LogoContainerCSS} />
      )
  }

  return <React.Fragment>{LogoContainerIMG}</React.Fragment>
}
