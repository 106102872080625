import { Link } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const About = (props) => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={8}>
            <Typography component="p" variant="h6" gutterBottom>
              Conferinţa se adresează profesorilor de ştiinţe din învăţământul
              preuniversitar (matematică, fizică, chimie, biologie, geografie,
              informatică, tehnologii, profesori învăţământ primar etc.),
              profesorilor consilieri școlari, studenților care se pregătesc
              pentru cariera didactică, universitarilor, cercetătorilor în
              educaţie, cercetătorilor ştiinţifici, membrilor unor organizaţii
              ce desfăşoară programe educaţionale, decidenţilor şi în general
              tuturor celor interesaţi de dezvoltarea unui învăţământ de
              calitate în domeniul STEM.
            </Typography>
            <Typography component="p" variant="h6">
              Numărul total de participanţi aşteptat este de aproximativ 150
              distribuit între diferitele categorii de participanți.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ py: 12, textAlign: 'left' }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Apel contribuții
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>
              Programul conferinței propune sesiuni dedicate pentru prezentarea
              experiențelor educaționale din activitatea proprie a
              participanților. Pentru cei interesați vă invităm să ne trimiteți
              propuneri pentru o prezentare de 15 minute pe următoarele teme:
              <ul>
                <li>
                  Bune practici în predarea științelor în contexte de criză
                </li>
                <li>
                  Proiecte educaționale desfășurate la nivel european, național
                  sau regional
                </li>
                <li>
                  Perspective privind dezvoltarea parteneriatelor școală -
                  comunitate
                </li>
                <li>
                  Strategii instituționale privind educația STEM (ex. STEM
                  school label)
                </li>
                <li>
                  Abordări de sprijin a comunităților vulnerabile prin educația
                  STEM (ex.ROSE)
                </li>
              </ul>
            </Typography>
            <Typography gutterBottom>
              Selecția prezentărilor se va face pe baza unui abstract trimis
              prin formularul de înscriere de mai jos.{' '}
              <strong>
                Selecția de participare la conferință nu este condiționată de
                prezentarea unei propuneri!.
              </strong>
            </Typography>
            <Typography gutterBottom sx={{mt:3}}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                href="https://docs.google.com/forms/d/e/1FAIpQLSd2lOk1p8APdojsR2YqapGJ6pHUXJoDYpA0L2jR9vFo79t45A/viewform?usp=sf_link"
                target="_blank"
              >
                Formular înscriere contribuții
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Costuri de participare
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>
              Taxa de participare pentru două zile - 400 lei
            </Typography>
            <hr />
            <Typography>
              Taxa de participare pentru două zile{' '}
              <strong>incluzând cazare</strong> - 700 lei
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ py: 12, textAlign: 'left' }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Sprijin participare
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>
              Organizatorii pot susține participarea în limita fondurilor
              disponibile a unui număr de beneficiari, astfel:
              <ol>
                <li>
                  Profesori care provin din unități de învățământ considerate
                  vulnerabile conform următoarelor criterii:
                  <ul>
                    <li>
                      <em>Unităţile de învăţământ liceal</em> eligibile sunt
                      unităţile considerate eligibile în proiectul ROSE derulat
                      de Guvernul României cu Banca Mondială, vezi lista{' '}
                      <a href="https://cnces2017.unibuc.ro/wp-content/uploads/2017/10/ROSE_Lista-licee-eligibile_actualizata.pdf">
                        aici
                      </a>
                    </li>
                    <li>
                      <em>Unităţile de învăţământ primar şi gimnazial</em>{' '}
                      eligibile sunt considerate cele din mediul rural situate
                      la o distanţă mai mare de 30 de km de un centru
                      universitar
                    </li>
                  </ul>
                </li>
                <li>
                  Profesori din învățământul preuniversitar care predau
                  discipline STEM: profesori învățământ primar, secundar
                  gimnazial și liceal
                </li>
                <li>
                  Profesori consilieri școlari din rețeaua de cabinete școlare
                  și interșcolare de asistență psihopedagogică CMBRAE - CJRAE
                </li>
                <li>
                  Studenți care se pregătesc pentru o carieră didactică, cu
                  recomandarea unui îndrumător
                </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          textAlign: 'left',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h3" variant="h5" align="center" gutterBottom>
              Sprijin financiar
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography component="h4" variant="h6" align="left" gutterBottom>
              Burse parţiale
            </Typography>
            <Typography gutterBottom>
              (acoperă taxa de conferinţă, mesele din timpul conferinţei,
              materiale educaționale):
              <ul>
                <li>
                  cadre didactice din învăţământul preuniversitar şi celelalte
                  categorii de participanţi în limita fondurilor disponibile
                </li>
              </ul>
            </Typography>
            <hr />
            <Typography component="h4" variant="h6" align="left" gutterBottom>
              Burse integrale
            </Typography>
            <Typography gutterBottom>
              (acoperă taxa conferinţei, mesele din timpul conferinţei,
              materiale educaționale, cheltuielile de cazare):
              <ul>
                <li>cadre didactice din unităţile vulnerabile </li>
                <li>
                  cadre didactice din învăţământul preuniversitar (în limita
                  fondurilor disponibile){' '}
                </li>
              </ul>
            </Typography>
            <hr />
            <Typography component="h4" variant="h6" align="left" gutterBottom>
              Bursele se acordă în condițiile prezenței la cel puțin două zile
              ale conferinței (28, 29 sau 30 aprilie) și la cel puţin 6 dintre
              activităţile conferinței.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default About
