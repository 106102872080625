import React from 'react'
import { NavLink } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import { useTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import MailIcon from '@mui/icons-material/Mail'
import PhoneIcon from '@mui/icons-material/Phone'
import MapIcon from '@mui/icons-material/LocationOn'

// import Icon from '@mdi/react'
// import { mdiMail, mdiPhone, mdiMap } from '@mdi/js'

import ContactForm from '../../comps/forms/ContactForm'
import SocialIcons from '../../comps/SocialIcons'

// import { theme } from '../../themes/MainTheme'

import SiteLogo from '../../comps/assets/SiteLogo'

export default withGoogleSheets()(function Footer(props) {
  const theme = useTheme()
  const currentYear = new Date().getFullYear()

  const data = props.db
  const config = Object.assign(
    {},
    data.config.reduce((r, { key, value }) => ((r[key] = value), r), {}),
  )
  const {
    siteName,
    siteLogo,
    siteLogoType,
    showSiteName,
    siteLanguage,
    siteCopyrightOwner,
    siteCopyrightOwnerURL,
    siteContactEmail,
    siteContactPhone,
    siteContactAddress,
    siteContactMapLink,
  } = config

  const classes = {
    root: {
      padding: theme.spacing(12, 8, 0),
      // [theme.breakpoints.down('sm')]: {
      //   padding: theme.spacing(10, 8, 0),
      // },
      backgroundColor: '#181818',
      color: 'white',
    },
    footerLeft: {
      // [theme.breakpoints.down('sm')]: {
      //   marginBottom: theme.spacing(8),
      //   textAlign: 'center',
      // },
    },
    footerRight: {},
    siteLogo: {
      width: theme.spacing(20),
      marginRight: theme.spacing(2),
      verticalAlign: 'top',
      fill: theme.palette.common.white,
    },
    siteLogoName: {
      marginTop: theme.spacing(2),
      // display: 'inline-block',
      // lineHeight: `${theme.spacing(12)}px`,
    },
    contactText: {
      paddingLeft: theme.spacing(2),
      lineHeight: `${theme.spacing(3)}px`,
      fontWeight: 300,
      textTransform: 'none',
      cursor: 'default',
      fontWeight: 400,
      color: 'white',
      // [theme.breakpoints.down('xs')]: {
      //   padding: theme.spacing(0.75, 2),
      // },
    },
    contactIcon: {
      // width: `${theme.spacing(3)}px !important`,
      // height: `${theme.spacing(3)}px !important`,
      // marginLeft: theme.spacing(-4),
      // marginRight: theme.spacing(3),
      verticalAlign: 'middle',
      // [theme.breakpoints.down('xs')]: {
      //   display: 'none',
      // },
    },
    copyright: {
      margin: theme.spacing(12, -8, 0),
      padding: theme.spacing(10, 0, 8),
      backgroundColor: '#121212',
      color: theme.palette.grey[400],
      textAlign: 'center',
    },
    designedBy: {
      textTransform: 'none',
      fontWeight: 400,
      color: '#424242',
      '&, &>a': {
        fontWeight: 400,
        color: theme.palette.grey[800],
        textTransform: 'none',
      },
    },
  }

  function createMarkup(content) {
    return { __html: content }
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{ pt: 8, pb: 16, textAlign: 'center' }}
          className="organizatori"
        >
          <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{
                  fontFamily:
                    'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
                }}
              >
                Organizatori
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img
                src="//cnces.educatiepentrustiinta.ro/images/cnces2022_logos_org.png"
                alt="Organizatori CNCES 2022"
              />
              {/* <Typography>
              <strong>UB</strong>, Universitatea din București
              <br />
              <strong>USV</strong>, Universitatea „Ștefan cel Mare” Suceava,{' '}
              <br />
              <strong>UBB</strong>, Universitatea Babeș-Bolyai, <br />
              <strong>INCDFP</strong>, Institutul Naţional de
              Cercetare-Dezvoltare pentru Fizica Pământului
              <br />
              <strong>IFIN-HH</strong>, Institutul Naţional pentru Fizică şi
              Inginerie Nucleară - Horia Hulubei
            </Typography> */}
            </Grid>
            <Grid item xs={12} sx={{ mt: 6 }}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{
                  fontFamily:
                    'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
                }}
              >
                Parteneri și sponsori
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <img
                src="//cnces.educatiepentrustiinta.ro/images/cnces2022_logos_part1.png"
                alt="Parteneri și sponsori CNCES 2022"
              />
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <img
                src="//cnces.educatiepentrustiinta.ro/images/cnces2022_logos_part2.png"
                alt="Parteneri și sponsori CNCES 2022"
              />
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <img
                src="//cnces.educatiepentrustiinta.ro/images/cnces2022_logos_part3.png"
                alt="Parteneri și sponsori CNCES 2022"
              />
            </Grid>
            <Grid item xs={12} md={10} sx={{mt: 4}}>
              <Typography variant="caption">
                The work presented on this website has received funding from the
                European Union’s H2020 research and innovation programme –
                project Scientix 4, coordinated by European Schoolnet (EUN) and
                project ReCoNnect, coordinated by IFIN-HH. The content of the
                website is the sole responsibility of the organizer and it does
                not represent the opinion of the European Commission (EC), and
                the EC is not responsible for any use that might be made of
                information contained.
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
            <Typography>
              <strong>ICUB</strong>, Universitatea din București <br />
              <a href="http://www.scientix.eu" target="_blank">
                Scientix
              </a>
              <br />
              <a
                href="https://noapteacercetatorilor.educatiepentrustiinta.ro"
                target="_blank"
              >
                ReCoNnect
              </a>
              <br />
              <br />
              <strong>RoEduSeis, STIM</strong>, INFP <br />
              <a href="https://fundatiacomunitarabucuresti.ro" target="_blank">
                Fundația Comunitară București
              </a>
              <br />
              IKEA
            </Typography>
          </Grid> */}
          </Grid>
        </Box>
      </Container>

      <footer style={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5} style={classes.footerLeft}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SiteLogo
                    siteLogo={siteLogo}
                    siteLogoType={siteLogoType}
                    siteName={siteName}
                    siteLogoPosition="Footer"
                  />
                  {showSiteName && (
                    <Typography variant="h6" style={classes.siteLogoName}>
                      {siteName}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <Grid container spacing={2}>
                    {siteContactEmail && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          size="small"
                          startIcon={
                            // <Icon
                            //   path={mdiMail}
                            //   size={1}
                            //   color="white"
                            //   style={classes.contactIcon}
                            // />
                            <MailIcon style={{ color: 'white' }} />
                          }
                          style={classes.contactText}
                          href={`mailto:${siteContactPhone}`}
                          target="_blank"
                        >
                          {siteContactEmail}
                        </Button>
                      </Grid>
                    )}
                    {siteContactPhone && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          size="small"
                          startIcon={
                            // <Icon
                            //   path={mdiPhone}
                            //   size={1}
                            //   color="white"
                            //   style={classes.contactIcon}
                            // />
                            <PhoneIcon style={{ color: 'white' }} />
                          }
                          style={classes.contactText}
                          href={`tel:${siteContactPhone}`}
                          target="_blank"
                        >
                          {siteContactPhone}
                        </Button>
                      </Grid>
                    )}
                    {siteContactAddress && (
                      <Grid item xs={12}>
                        <Button
                          size="small"
                          variant="text"
                          startIcon={
                            // <Icon
                            //   path={mdiMap}
                            //   size={1}
                            //   color="white"
                            //   style={classes.contactIcon}
                            // />
                            <MapIcon style={{ color: 'white' }} />
                          }
                          style={classes.contactText}
                          href={siteContactMapLink}
                          target="_blank"
                        >
                          <span
                            dangerouslySetInnerHTML={createMarkup(
                              siteContactAddress,
                            )}
                          />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={5} style={classes.footerRight}>
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
        <div style={classes.copyright}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SocialIcons
                  size=""
                  showIcons={[
                    {
                      name: 'Facebook',
                      url: 'https://www.facebook.com/educatiepentrustiinta',
                    },
                    // {
                    //   name: 'Instagram',
                    //   url: 'https://www.instagram.com/',
                    // },
                    // { name: 'Pinterest', url: 'https://pinterest.com' },
                    // { name: 'Twitter', url: 'https://twitter.com' },
                    // { name: 'Behance', url: 'https://behance.net' },
                    // { name: 'Dribbble', url: 'https://dribbble.com' },
                  ]}
                  // square
                />
                <Typography variant="caption" component="div">
                  Copyright &copy; {currentYear}{' '}
                  <Button
                    size="small"
                    color="inherit"
                    variant="text"
                    href={siteCopyrightOwnerURL}
                    target="_blank"
                  >
                    {siteCopyrightOwner}
                  </Button>
                  <br />
                  Toate drepturile rezervate.
                </Typography>
                {/* <Button
                  size="small"
                  color="inherit"
                  variant="text"
                  to="/ro/politica-de-confidentialitate"
                  component={NavLink}>
                  Politica de confidențialitate
                </Button> */}
              </Grid>
            </Grid>
            <br />
            <Typography
              variant="caption"
              component="div"
              style={classes.designedBy}
            >
              designed and developed by{' '}
              <Button
                size="small"
                href="https://edgencia.ro"
                target="_blank"
                style={{
                  textTransform: 'none',
                  fontWeight: 400,
                  color: '#424242',
                }}
              >
                edgencia
              </Button>
            </Typography>
          </Container>
        </div>
      </footer>
    </>
  )
})
