import { Link } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import { styled, useTheme } from '@mui/material/styles'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const HomePage = (props) => {
  const theme = useTheme()

  const data = props.db
  const config = Object.assign(
    {},
    data.config.reduce((r, { key, value }) => ((r[key] = value), r), {}),
  )
  const {
    siteName,
    siteNameFull,
    siteNameCaption,
    siteNameSlogan,
    siteAffiliation,
    siteContactAddress,
  } = config

  return (
    <Container
      maxWidth="lg"
      // style={{ paddingTop: '128px', marginBottom: '128px' }}
      // component="main"
    >
      <Box sx={{ pt: 2, pb: 6 }}>
        <Typography align="center" gutterBottom>
          {siteAffiliation}
        </Typography>
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          {siteName}
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          gutterBottom
          style={{
            fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
          }}
        >
          {siteNameSlogan}
        </Typography>
        <Typography align="center" gutterBottom>
          {siteNameCaption}
        </Typography>
      </Box>

      <Box
        sx={{ px: 8, py: 12, backgroundColor: theme.palette.secondary.main }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5" align="center" gutterBottom>
              Conferința susține schimbul de idei și de bune practici în
              domeniul Educației pentru Știință promovând inițiativele de
              realizare a comunităților de învățare, de bune practici, a
              ecosistemelor ce susțin dezvoltarea și orientarea profesională
              pentru elevi și studenți
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="h3" variant="h6" align="right" gutterBottom>
              În cadrul acestei ediții vom pune în discuție perspectivele de
              dezvoltare a educației STEM după o perioadă plină de tulburări
              sociale la nivel global, care validează încă o dată afirmația
              conform căreia viitorul este mai aproape și mai imprevizibil decât
              am fi crezut. Îi vom provoca pe participanți în cadrul
              atelierelor, prezentărilor și meselor rotunde să reflecteze la
              schimbările în ceea ce privește finalitățile educaționale, rolul
              profesorilor, organizarea educației și a școlii pe care ultimele
              evenimentele le anunță. Vom porni discuția de la scenariile
              privind parteneriatele școală - societate pe care studiile recente
              ale UNESCO și OECD le proiectează.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="h3" variant="h6" align="left" gutterBottom>
              Temele sunt ilustrate urmărind obiectivele generale ale
              conferinței:
            </Typography>
            <ul>
              <li>împărtășirea de bune practici,</li>
              <li>
                încurajarea dialogului între diferite categorii interesate de
                domeniul educației STEM și a carierelor în domeniu,
              </li>
              <li>
                consolidarea de parteneriate destinate dezvoltării comunității
                pentru educația STEM,
              </li>
              <li>
                promovarea cercetării în domeniul educației STEM pentru
                fundamentarea politicilor educaționale în domeniu,
              </li>
            </ul>
            <Typography component="h3" variant="h6" align="left" gutterBottom>
              în cadrul unor ateliere, prezentări, mese rotunde cu dezbateri,
              vizite în laboratoare educaționale și de cercetare.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* <Box sx={{ py: 12, textAlign: 'justify' }}></Box> */}

      <Box sx={{ py: 12, textAlign: 'center' }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              gutterBottom
              style={{
                fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
              }}
            >
              Program
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Joi, 28 aprilie</strong> - vizite în campusul
              Universității Ștefan cel Mare, deschidere, panel invitați
              <br />
              <strong>Vineri, 29 aprilie</strong> - ateliere, mese rotunde,
              sesiuni prezentări, cină festivă
              <br />
              <strong>Sâmbătă, 30 aprilie</strong> - ateliere, concluzii și
              închidere
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="contained"
              component={Link}
              to="program"
            >
              Program
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          px: 8,
          py: 12,
          textAlign: 'justify',
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              gutterBottom
              style={{
                fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
              }}
            >
              Informații despre sprijinul financiar
            </Typography>
          </Grid>
          {/* <Hidden smDown>
              <Grid item xs={false} md={3} />
            </Hidden> */}
          <Grid item xs={12} md={6}>
            <Typography align="center">
              Prin eforturile organizatorilor, participarea profesorilor și a
              consilierilor școlari din mediul preuniversitar va fi susținută
              parțial sau integral în conformitate cu condițiile menționate în
              secțiunea{' '}
              <Button color="secondary" component={Link} to="participare">
                Participare
              </Button>
              . Cuantumul taxei de participare este de{' '}
              <strong>400 de lei</strong>.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          px: 8,
          py: 12,
          textAlign: 'justify',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              gutterBottom
              style={{
                fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
              }}
            >
              Despre noi
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography align="center" gutterBottom>
              <strong>Comunitatea Educație pentru Știință</strong> reprezintă un
              grup de inițiativă care are obiectivul de a crea o un mediu
              profesional integrator în domeniul educației pentru științe, prin
              dezvoltarea unei platforme de dialog și acțiune.
            </Typography>
            <Typography align="center">
              <Button
                color="primary"
                size="large"
                variant="outlined"
                component={Link}
                to="/ro/despre-noi"
              >
                Mai multe informații
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Box>

    </Container>
  )
}

export default withGoogleSheets()(HomePage)
