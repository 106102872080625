import { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import ReactMarkdown from 'react-markdown';
import remarkRehype from 'remark-rehype'
import remarkGfm from 'remark-gfm'

import Masonry from 'react-masonry-css'

import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardActionArea from '@mui/material/CardActionArea'
import Chip from '@mui/material/Chip'

import moment from '../../../helpers/moment'

import CustomLoadingComponent from '../../assets/CustomLoading';

const MasonryBreakpoints = {
  default: 3,
  1900: 3,
  1279: 2,
  599: 1,
}

const s3Prez = [
  {
    title: 'Scientix în Republica Moldova',
    org: 'Rodica Cujba, UTM',
    programType: 'prez',
  },
  {
    title: 'Metode noi de training / educatie, cu exemplu de proiect VR Study',
    org: 'Gabriel Tironeac, ASSIST Software',
    programType: 'prez',
  },
  {
    title: 'Școala de vară de Știință și Tehnologie de la Măgurele',
    org: 'Bogdan Popovici, Ioana Fidel, Andrei Hotnog, IFIN-HH',
    programType: 'prez',
  },
  {
    title:
      'Învățarea bazată pe investigație în cadrul unei școli deschise – experiența școlilor PULCHRA',
    org: 'Petre Botnariuc, Centrul Național de Politici și Evaluare în Educație (CNPEE)',
    programType: 'prez',
  },
  {
    title: 'Școala de vară „Geodiversitate în Maramureș”',
    org: 'Conf. Dr. Ioan Denuț, Universitatea Tehnică din Cluj-Napoca, Muzeul de Mineralogie ”Victor Gorduza” Baia Mare',
    programType: 'prez',
  },
]
const p3Prez = [
  {
    title: 'Bune practici în predarea științelor în contexte de criză',
    programType: 'prez',
  },
  {
    title:
      'Proiecte educaționale desfășurate la nivel european, național sau regional - I',
    programType: 'prez',
  },
  {
    title:
      'Proiecte educaționale desfășurate la nivel european, național sau regional - II',
    programType: 'prez',
  },
  {
    title:
      'Perspective privind dezvoltarea parteneriatelor școală - comunitate',
    programType: 'prez',
  },
]

const Program = (props) => {
  const theme = useTheme()

  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState([])

  const program = props.db.program
  const programTypes = {
    aprim: { title: 'Atelier Primar', color: 'success' },
    acons: { title: 'Atelier Consilieri', color: 'info' },
    prez: { title: 'Prezentări', color: 'error' },
    mese: { title: 'Mese rotunde de discuții', color: 'default' },
  }

  useEffect(() => {
    fetch(
      'https://api.scicommtoolbox.ro/api/event-activities/cnces22?categories[]=Ateliere&categories[]=Emisiuni&categories[]=Dezbateri&categories[]=Prezent%C4%83ri%2C%20prelegeri&perPage=40',
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setItems(result.data.activities.data)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true)
          setError(error)
        },
      )
  }, [])
    
  // console.log(activities)

if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <CustomLoadingComponent backgroundColor="transparent" width="100%" height="100%" />
  } else {
    // const activities = api.data.activities.data
    const activities = items
  // console.log(items.data)
    return (
      <Box
        sx={{
          // px: 8,
          py: 12,
          // backgroundColor: theme.palette.secondary.main,
          // color: 'white',
          textAlign: 'left',
          '& .MuiTableCell-body>h4': {
            // fontSize: '1rem',
            // verticalAlign: 'top',
          },
          '& .MuiTableCell-body>strong': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontSize: '1rem',
            // lineHeight: '28px',
          },
        }}
      >
        <Grid container spacing={3}
          // sx={{ justifyContent: 'center' }}
        >
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Program Conferință
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Table className="programSheet">
              <TableBody>
                {program.map((entry) => {
                  const dayActivities = activities.filter(
                    (el) => el.startDate === entry.day,
                  )
                  // console.log(dayActivities)
                  return (
                    <Fragment key={entry.day}>
                      <TableRow
                        // hover
                        sx={{ backgroundColor: theme.palette.secondary.main }}
                        className="dayHead"
                      >
                        <TableCell colSpan="6">
                          <Typography component="h3" variant="h5" align="center">
                            {moment(entry.day).format('dddd, DD MMMM YYYY')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {/* <TableHead></TableHead> */}
                      {props.db[entry.dataSource].map((interval, index) => {
                        const hourActivities = dayActivities.filter(
                          (el) =>
                            el.startTime === interval.start ||
                            interval.start === "15:00:00" && el.startTime === '15:45:00',
                        )
                        // console.log(entry.day, interval.start, hourActivities)

                        return (
                          <TableRow hover key={`${interval.title}-${index}`}>
                            <TableCell className="start">
                              <strong>{interval.start}</strong>
                            </TableCell>
                            <TableCell className="end">
                              <strong>{interval.end}</strong>
                            </TableCell>
                            <TableCell className="slotActivities">
                              <ReactMarkdown
                                children={interval.title}
                                components={{
                                  h4: ({ node, ...props }) => (
                                    <Typography
                                      variant="h5"
                                      component="h4"
                                      // sx={{ lineHeigh: '22.8px !important' }}
                                      {...props}
                                    />
                                  ),
                                }}
                                // remarkPlugins={[remarkRehype, remarkGfm]}
                              />
                              {hourActivities.length > 0 && (
                                <Masonry
                                  breakpointCols={MasonryBreakpoints}
                                  className="masonry-grid"
                                  columnClassName="masonry-grid_column"
                                >
                                  {entry.day === '2022-04-29' &&
                                    interval.start === '17:00:00' &&
                                    p3Prez.map((p3p) => (
                                      <Card
                                        elevation={2}
                                        sx={{ mt: 3 }}
                                        key={p3p.title}
                                      >
                                        <CardContent
                                          className={p3p.programType}
                                        >
                                          <Typography
                                            // gutterBottom
                                            component="h5"
                                            variant="h6"
                                            // component="div"
                                            sx={{ mb: 2 }}
                                          >
                                            {p3p.title}
                                          </Typography>
                                          <Chip
                                            label={
                                              programTypes[p3p.programType]
                                                .title
                                            }
                                            size="small"
                                            // variant="outlined"
                                            color={
                                              programTypes[p3p.programType]
                                                .color
                                            }
                                          />
                                        </CardContent>
                                      </Card>
                                    ))}

                                  {hourActivities.map((hact, index) => (
                                    <Card
                                      elevation={2}
                                      sx={{ mt: 3 }}
                                      key={hact.slug}
                                    >
                                      <CardActionArea
                                        href={`https://scicommtoolbox.ro/activitati/${hact.slug}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <CardMedia
                                          component="img"
                                          height="140"
                                          image={hact.heroImage}
                                          alt={hact.title}
                                        />
                                        <CardContent
                                          className={hact.programType}
                                        >
                                          <Typography
                                            // gutterBottom
                                            component="h5"
                                            variant="h6"
                                            // component="div"
                                            sx={{ mb: 2 }}
                                          >
                                            {hact.title}
                                          </Typography>
                                          {/* <Typography
                                            variant="body2"
                                            color="text.secondary"
                                          >
                                            Lizards are a widespread group of
                                            squamate reptiles, with over 6,000
                                            species, ranging across all continents
                                            except Antarctica
                                          </Typography> */}
                                          {hact.programType ? (
                                            <Chip
                                              label={
                                                programTypes[hact.programType]
                                                  .title
                                              }
                                              size="small"
                                              color={
                                                programTypes[hact.programType]
                                                  .color
                                              }
                                            />
                                          ) : (
                                            <Chip
                                              label="Atelier profesori STEM"
                                              size="small"
                                              // variant="outlined"
                                              color="default"
                                            />
                                          )}
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                  ))}

                                  {entry.day === '2022-04-30' &&
                                    interval.start === '09:00:00' &&
                                    s3Prez.map((s3p) => (
                                      <Card
                                        elevation={2}
                                        sx={{ mt: 3 }}
                                        key={s3p.title}
                                      >
                                        <CardContent
                                          className={s3p.programType}
                                        >
                                          <Typography
                                            // gutterBottom
                                            component="h5"
                                            variant="h6"
                                            // component="div"
                                            sx={{ mb: 2 }}
                                          >
                                            {s3p.title}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color="text.secondary"
                                          >
                                            {s3p.org}
                                          </Typography>
                                          <Chip
                                            sx={{ mt: 2 }}
                                            label={
                                              programTypes[s3p.programType]
                                                .title
                                            }
                                            size="small"
                                            color={
                                              programTypes[s3p.programType]
                                                .color
                                            }
                                          />
                                        </CardContent>
                                      </Card>
                                    ))}
                                </Masonry>
                              )}
                            </TableCell>
                          </TableRow>
                        )})}
                    </Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default withGoogleSheets()(Program)
