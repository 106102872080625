import { Link } from 'react-router-dom'

import Container from '@mui/material/Container'

const NotFoundPage = () => (
  <Container
    maxWidth="lg"
    style={{ paddingTop: '128px', marginBottom: '128px' }}
    component="main"
  >
    <h1>It looks like you're lost...</h1>
    <p>
      <Link to="/">Go to the home page</Link>
    </p>
  </Container>
)

export default NotFoundPage
