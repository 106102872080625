import * as React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes, Navigate, Link } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'
import ReactGA from 'react-ga'
import WebFont from 'webfontloader'

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  styled,
  useTheme,
} from '@mui/material/styles'
import { amber, deepPurple, grey } from '@mui/material/colors'

import useMediaQuery from '@mui/material/useMediaQuery'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Zoom from '@mui/material/Zoom'
import Fab from '@mui/material/Fab'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import moment from './helpers/moment'

import Layout from './layout/Defaultlayout';
import NotFoundPage from './pages/NotFoundPage'
import DefaultPage from './pages/DefaultPage'
import HomePage from './pages/HomePage'

import Footer from './comps/page/Footer'

import './styles.css'

WebFont.load({
  google: {
    families: [
      'Bree+Serif:400:latin,latin-ext',
      'Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i:latin,latin-ext',
      'sans-serif',
    ],
  },
})

function initializeReactGA() {
  ReactGA.initialize('UA-153167348-2')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const mainColor = '#c27766'

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...deepPurple,
      ...(mode === 'dark' && {
        main: mainColor,
      }),
    },
    secondary: {
      ...amber,
    },
    // ...(mode === 'dark' && {
    //   background: {
    //     default: grey[900],
    //     paper: grey[800],
    //   },
    // }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
  typography: {
    // fontFamily: 'OpenSans, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontWeightLight: 100,
    fontWeightRegular: 300,
    h1: {
      // fontFamily: 'Domine',
    },
    h2: {
      // fontFamily: 'Domine',
    },
    h3: {
      fontFamily: '"Bree Serif", Roboto, Helvetica, Arial, sans-serif',
    },
    h4: {
      fontFamily: '"Bree Serif", Roboto, Helvetica, Arial, sans-serif',
    },
    h5: {
      fontFamily: '"Bree Serif", Roboto, Helvetica, Arial, sans-serif',
    },
    h6: {
      fontFamily: '"Bree Serif", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1.2rem',
      lineHeight: 1.37,
    },
  },
})

let selectModeTheme = createTheme(getDesignTokens('light'))
selectModeTheme = responsiveFontSizes(selectModeTheme)

function ScrollTop(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    )

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  )
}
ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
}

export default withGoogleSheets()(function App(props) {
  const data = props.db

  const lang = 'ro'

  moment.locale('ro')
  // initializeReactGA()

  // console.log(data)

  return (
    <ThemeProvider theme={selectModeTheme}>
      <Routes>
        <Route exact path="/" element={<Navigate replace to="/ro" />} />
        <Route path="/ro" element={<Layout lang={lang} />}>
          {data.routes.map((page) => {
            // console.log(page)
            if (JSON.parse(page.route.toLowerCase())) {
              if (page.level === 'index') {
                return (
                  <Route
                    index
                    element={<HomePage title={page.name} />}
                    key={`route-${page.component}`}
                  />
                )
              }
              return (
                <Route
                  path={page.path}
                  element={<DefaultPage title={page.name} page={page.path} />}
                  key={`route-${page.component}`}
                />
              )
            }
          })}
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </ThemeProvider>
  )
})
