import { Link } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Program from './program/Program'
import Invitati from './program/Invitati'

const About = (props) => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={8}>
            <Typography component="p" variant="h6" gutterBottom>
              Programul constă din sesiuni plenare și sesiuni paralele din
              următoarele categorii: sesiuni de prezentări invitate, ateliere,
              mese rotunde, sesiuni de prezentări de bune practici.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Program />

      <Invitati dataSource="invitati" bgColor={theme.palette.secondary.main} title="Invitați" />
    </>
  )
}

export default About
