import * as React from 'react'
import PropTypes from 'prop-types'
import { Outlet, Link } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import { styled, useTheme } from '@mui/material/styles'

import { useLocation } from 'react-router-dom'

import useScrollTrigger from '@mui/material/useScrollTrigger'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'

import RouteScrollTop from '../comps/assets/RouteScrollTop'
import SiteLogo from '../comps/assets/SiteLogo'

// import MainNav from '../components/MainNav'
// import SideNav from '../components/SideNav'
import Footer from '../comps/page/Footer'

const drawerWidth = 288

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -drawerWidth + 58,
    marginBottom: 64,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
)

function ElevationScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}
ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

export default withGoogleSheets()(function DefaultLayout(props) {
  const location = useLocation()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const data = props.db
  const config = Object.assign(
    {},
    data.config.reduce((r, { key, value }) => ((r[key] = value), r), {}),
  )
  const {
    siteLogo,
    siteLogoType,
    siteName,
    siteNameShort,
    siteNameFull,
    siteNameSlogan,
    siteDefaultLanguage,
  } = config

  const sxAppBar = {
    top: theme.spacing(2),
    right: theme.spacing(3),
    left: theme.spacing(3),
  }

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // console.log(location, location.pathname.includes('program'))
  
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          mt: 0,
          mb: 4,
        }}
        id="back-to-top-anchor"
      >
        {/* <img
          src="https://cnces.educatiepentrustiinta.ro/images/slider/cnces2022-v2_828x360.png"
          alt={siteNameFull}
          style={{
            width: '100vw',
            margin: '0 auto',
          }}
        /> */}
        {/* <source
            media="(min-width: 968px)"
            srcset="//cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-l.jpg"
          />
          <source
            media="(min-width: 1281px)"
            srcset="//cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-l.jpg"
          />
          <source
            media="(min-width: 1921px)"
            srcset="//cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-xl.jpg"
          /> */}
        <img
          srcset="//cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-s.jpg 967w,
                  //cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-m.jpg 1280w,
                  //cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-l.jpg 1920w,
                  //cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-xl.jpg 2560w"
          sizes="100vw"
          src="//cnces.educatiepentrustiinta.ro/images/slider/cnces2022_banner_sigle-v3-xl.jpg"
          alt={siteNameFull}
          style={{
            // width: '100vw',
            margin: '0 auto',
          }}
        />
      </Box>
      {/* <Toolbar sx={{ mt: 2, mb: 6 }} /> */}
      <ElevationScroll {...props}>
        <AppBar
          position="fixed"
          sx={{
            ...sxAppBar,
            width: `calc(100vw - ${theme.spacing(7)})`,
            padding: '0 !important',
          }}
        >
          <Toolbar>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                // justifyContent: { xs: 'center', md: 'flex-start' },
              }}
            >
              <SiteLogo
                siteLogo={siteLogo}
                siteLogoType={siteLogoType}
                siteName={siteName}
                siteLogoPosition="AppBar"
              />
              <Typography variant="h6" component="div">
                {matchesXS ? siteNameShort : siteName}
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', lg: 'flex' },
                justifyContent: 'flex-end',
              }}
            >
              {data.routes.map(
                (page) =>
                  JSON.parse(page.nav.toLowerCase()) &&
                  (page.level === 'index' ? (
                    <IconButton
                      key={`menu-${page.path}`}
                      component={Link}
                      to="/"
                      sx={{
                        mr: 1,
                        color: 'white',
                      }}
                    >
                      {/* {page.name} */}
                      <HomeIcon />
                    </IconButton>
                  ) : (
                    <Button
                      key={`menu-${page.path}`}
                      component={Link}
                      to={page.path}
                      dataTo={page.path}
                      sx={{
                        color: location.pathname.includes(page.path)
                          ? theme.palette.secondary.main
                          : 'white',
                      }}
                    >
                      {page.name}
                    </Button>
                  )),
              )}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' } }}>
              <IconButton
                color="inherit"
                size="large"
                edge="end"
                aria-label="Main Menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                // sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', lg: 'none' },
                }}
                PaperProps={{
                  sx: {
                    minWidth: 192,
                    borderRadius: 0,
                    backgroundColor: '#212223',
                    // backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                {data.routes.map(
                  (page) =>
                    JSON.parse(page.nav.toLowerCase()) &&
                    (page.path ? (
                      <MenuItem
                        key={`mobileMenu-${page.component}`}
                        component={Link}
                        to={page.path}
                        onClick={handleCloseNavMenu}
                        sx={{
                          color: 'white',
                          '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                            color: '#212223',
                          },
                        }}
                      >
                        {page.name}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={`mobileMenu-${page.component}`}
                        component={Link}
                        to="/"
                        onClick={handleCloseNavMenu}
                        sx={{
                          color: 'white',
                          '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                            color: '#212223',
                          },
                        }}
                      >
                        {page.name}
                      </MenuItem>
                    )),
                )}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <RouteScrollTop />

      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      {/* <Main></Main> */}

      <Main>
        <Outlet />
      </Main>

      <Footer />
    </Container>
  )
})
