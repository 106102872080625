import { Link } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import ProgramPage from '../comps/content/ProgramPage'
import Participare from '../comps/content/Participare'
import Inscriere from '../comps/content/Inscriere'
import About from '../comps/content/About'

const DefaultPage = (props) => {
  const theme = useTheme()
  const matchesSMd = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Container
      maxWidth="lg"
      // style={{ paddingTop: '128px', marginBottom: '128px' }}
      // component="main"
    >
      {/* <Container maxWidth="lg"> */}
      <Box sx={{ mt: { xs: 2, md: -14 }, pb: 4 }}>
        <Typography
          component="h1"
          variant="h1"
          align={matchesSMd ? 'center' : 'right'}
          gutterBottom
        >
          {/* {siteNameFull} */}
          {props.title}
        </Typography>
      </Box>
      {props.page === 'participare' && <Participare />}
      {props.page === 'inscriere' && <Inscriere />}
      {props.page === 'program' && <ProgramPage />}
      {props.page === 'despre-noi' && <About />}
    </Container>
  )
}

export default DefaultPage
