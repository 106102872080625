import React from 'react'

import { useTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Fab from '@mui/material/Fab'

import FacebookIcon from '@mui/icons-material/Facebook'
// import Icon from '@mdi/react'
// import Icon from '@mdi/react'
// import {
//   mdiFacebook,
//   mdiInstagram,
//   mdiPinterest,
//   mdiTwitter,
//   mdiBehance,
//   mdiDribbble,
// } from '@mdi/js'
const iconsList = {
  Facebook: FacebookIcon,
  //   Facebook: mdiFacebook,
  //   Instagram: mdiInstagram,
  //   Pinterest: mdiPinterest,
  //   Twitter: mdiTwitter,
  //   Behance: mdiBehance,
  //   Dribbble: mdiDribbble,
}

export default function ProductList(props) {
  const theme = useTheme()
  const socialIconSize = props.size
    ? props.size
    : // : isWidthUp('sm', props.width)
      // ? 'large'
      'medium'

  const classes = {
    root: {},
    socialIcon: {
      margin: theme.spacing(0, 1, 4),
      // [theme.breakpoints.down('sm')]: {
      //   margin: theme.spacing(0, 1, 4),
      // },
      backgroundColor: 'transparent',
      // borderColor: theme.palette.grey[800],
      // border: `1px solid ${theme.palette.grey[900]}`,
      // border: `2px solid #181818`,
      border: `none`,
      boxShadow: 'none',
      color: theme.palette.grey[800],
      // '&[aria-label="Facebook"]:hover': { backgroundColor: '#1877f2' },
      // '&[aria-label="Instagram"]:hover': {
      //   backgroundImage:
      //     'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),  linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)',
      // },
      // '&[aria-label="Pinterest"]:hover': { backgroundColor: '#cb2128' },
      // '&[aria-label="Twitter"]:hover': { backgroundColor: '#00aced' },
      // '&[aria-label="Behance"]:hover': { backgroundColor: '#007cff' },
      // '&[aria-label="Dribbble"]:hover': { backgroundColor: '#ea4c89' },
    },
  }
  const handleSocialClick = (el) => {
    window.open(el, '_blank')
  }

  return (
    <Container maxWidth="xl">
      {props.showIcons.map((icon, index) => {
        // console.log(icon.name, index)
        const safeName = icon.name.trim()
        const SocialIcon = iconsList[safeName]

        return (
          <Fab
            size={socialIconSize}
            color="default"
            style={classes.socialIcon}
            aria-label={safeName}
            onClick={() => handleSocialClick(icon.url)}
            key={index}
            // style={props.square && { borderRadius: 0 }}
          >
            {/* <Icon path={SocialIcon} size={1.3} color="white" /> */}
            <SocialIcon />
          </Fab>
        )
      })}
    </Container>
  )
}
