import { Link } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import FAQ from './FAQ'

const About = (props) => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'left',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={8}>
            <Typography component="p" variant="h6" gutterBottom>
              Conferința se desfăşoară în perioada 28-30 aprilie prin
              completarea formularului de înregistrare. Participanţii pot să îşi
              aleagă zilele în care doresc să participe la activităţi ( 1 - 3
              zile) şi tipul de activităţi la care doresc să participe.
            </Typography>
            <Typography component="p" variant="h6" gutterBottom>
              Sprijinul financiar se acordă conform condiţiilor de eligibilitate
              descrise în pagina <Link to="/ro/participare">PARTICIPARE</Link>.
            </Typography>
            <Typography component="p" variant="subtitle2" gutterBottom>
              După completarea formularului veţi primi un mesaj automat de
              confirmare a înregistrării cererii și un link prin care veţi avea
              posibilitatea să faceţi modificări ale datelor înregistrate, dacă
              este cazul.{' '}
            </Typography>
            <Typography component="p" variant="subtitle2" gutterBottom>
              Selecţia se va face în limita locurilor disponibile luând în
              considerare următoarele criterii:
              <ul>
                <li>apartenenţa la una dintre categoriile grupului ţintă</li>
                <li>
                  opțiunile privind numărul și datele zilelor de participare
                </li>
                <li>opţiunile exprimate pentru participarea la activităţi</li>
                <li>
                  fondurilor disponibile pentru solicitanții de burse de
                  participare
                </li>
                <li>
                  asigurarea unei distribuţii echilibrate regional, în acord cu
                  criteriile specifice ale sponsorilor conferinţei
                </li>
              </ul>
            </Typography>
            <Typography gutterBottom>
              <Button
                size="large"
                variant="contained"
                fullWidth
                href="https://docs.google.com/forms/d/e/1FAIpQLSdg9aIddzD34pbrWUhQZUhWpBFK7c4BDaZuu1HKM4m7SQREwg/viewform"
                target="_blank"
              >
                Formular de înscriere
              </Button>
            </Typography>
            <Typography component="p" variant="h6" gutterBottom>
              Organizatorii susţin participarea a aproximativ 100 de
              participanţi dintre cadrele didactice din învăţământul
              preuniversitar, precum și studenții ce se pregătesc pentru o
              carieră didactică.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ py: 12, textAlign: 'left' }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Date importante
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>
              <ul>
                <li>
                  10 martie - 8 aprilie - Înregistrarea cererilor de participare
                </li>
                <li>
                  31 martie - anunțarea programului de ateliere și a invitaților
                  la conferință
                </li>
                <li>12 aprilie - Anunţarea participanților</li>
                <li>
                  16 aprilie - Data limită pentru confirmarea participării
                </li>
                <li>
                  19 aprilie - Selectarea opţiunilor pentru participarea la
                  activitățile care se desfășoară în paralel (ateliere, mese
                  rotunde sesiuni de prezentări)
                </li>
                <li>
                  22 aprilie - Finalizarea listelor de participanți și a
                  distribuției pe sesiuni
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.secondary.main,
          // color: 'white',
          textAlign: 'left',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Întrebări și răspunsuri
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <FAQ />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default About
