import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const CustomLoadingComponent = ({ width, height, backgroundColor }) => (
  <Box
    sx={{
      poition: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: width || '100vw',
      height: height || '100vh',
      backgroundColor: backgroundColor || '#212223',
    }}
  >
    <CircularProgress size={128} thickness={1} />
  </Box>
)

export default CustomLoadingComponent