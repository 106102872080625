import { Link } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import Invitati from './program/Invitati'

const About = (props) => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={6}>
            <Typography component="p" variant="h6" gutterBottom>
              <strong>Comunitatea Educație pentru Știință</strong> reprezintă un
              grup de inițiativă care are obiectivul de a crea un mediu
              profesional integrator în domeniul educației pentru științe prin
              dezvoltarea unei platforme de dialog și acțiune. Această platformă
              va susține schimbul de experiență și expertiză cu scopul de a
              facilita învățarea reciprocă pentru toți actorii implicați, în
              armonie cu tendințele și răspunsurile recente, noile provocări și
              orizonturile noi.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Invitati dataSource="comitet" title="Comitet de organizare" />

      <Box
        sx={{
          px: 8,
          py: 12,
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={8}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Ediții anterioare
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  component="h3"
                  variant="h5"
                  align="center"
                  gutterBottom
                >
                  CNCES 2019
                </Typography>
                {/* <Typography component="div" align="center" gutterBottom>
                    text
                  </Typography> */}
                <Typography align="center">
                  <Button
                    color="primary"
                    variant="contained"
                    href="https://cnces2019.educatiepentrustiinta.ro"
                    target="_blank"
                  >
                    Site-ul CNCES 2019
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  component="h3"
                  variant="h5"
                  align="center"
                  gutterBottom
                >
                  CNCES 2017
                </Typography>
                {/* <Typography component="div" align="center" gutterBottom>
                    text
                  </Typography> */}
                <Typography align="center">
                  <Button
                    color="primary"
                    variant="contained"
                    href="https://cnces2017.unibuc.ro"
                    target="_blank"
                  >
                    Site-ul CNCES 2017
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  component="h3"
                  variant="h5"
                  align="center"
                  gutterBottom
                >
                  CNCES 2015
                </Typography>
                {/* <Typography component="div" align="center" gutterBottom>
                    text
                  </Typography> */}
                <Typography align="center">
                  <Button
                    color="primary"
                    variant="contained"
                    href="https://cnces2017.unibuc.ro/despre-noi/cnces-2015/"
                    target="_blank"
                  >
                    Site-ul CNCES 2015
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default About
