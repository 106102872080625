import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import GoogleSheetsProvider from 'react-db-google-sheets'

import CssBaseline from '@mui/material/CssBaseline'

import CustomLoadingComponent from './comps/assets/CustomLoading'

import App from './App'

const config = {
  dataLoading: {
    component: CustomLoadingComponent,
  },
}

const rootElement = document.getElementById('root')
ReactDOM.render(
  <GoogleSheetsProvider config={config}>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </GoogleSheetsProvider>,
  rootElement,
)
