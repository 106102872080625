import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function SimpleAccordion() {
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  
  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component="p" variant="h6">
            Pot participa prin plata taxei de conferinţă?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Participarea la conferință cu plata taxei sau cu bursă se face în
            numărul de locuri alocat fiecărei categorii de participanți.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography component="p" variant="h6">
            Care sunt categoriile care pot beneficia de bursă parţială sau
            integrală de participare?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Toţi cei interesaţi de dezvoltarea unui învăţământ de calitate în
            domeniul STEM în România pot beneficia de burse parţiale de
            participare în limita unor cote stabilite pentru fiecare categorie
            de participanţi.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography component="p" variant="h6">
            Pot să candidez în același timp opțiunile de participare cu bursă
            integrală sau cu o bursă parțială?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Da, în cadrul selecției cererea va fi luată în considerare la ambele
            categorii. Invitarea dumneavoastră la conferința se va face după
            confirmarea participării în categoria la care ați fost selectat.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography component="p" variant="h6">
            Pot să aleg să particip la o singură zi de conferinţă?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Da, în acest caz nu puteţi fi beneficiarul unei burse integrale.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography component="p" variant="h6">
            Pot să mă înscriu la conferință dacă nu propun o prezentare, am
            șanse să fiu admis(ă)?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Selecția participanților este independentă de selecția propunerilor
            de prezentări.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography component="p" variant="h6">
            Dacă nu îmi este acceptată propunerea de prezentare pot în
            continuare să particip?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dacă ați fost admis(ă) la conferință, dar propunerea dumneavoastră
            nu a fost selectată, puteți în continuare să participați.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography component="p" variant="h6">
            Pentru participarea la conferinţă se acordă diplome de participare?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Da, participarea la conferinţă este recunoscută prin diplome de
            participare.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography component="p" variant="h6">
            Pentru cei care propun contribuții la conferință, pot fi mai mulți
            autori pentru o singură prezentare?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            O prezentare poate avea unul sau mai mulți autori dacă aceștia sunt
            fiecare înscriși și admiși la Conferință. Contribuția este înscrisă
            o singură dată de către unul din autori.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9a-content"
          id="panel9a-header"
        >
          <Typography component="p" variant="h6">
            Se pot să propun mai multe prezentări?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Da, puteți propune mai multe subiecte. În acest caz veți face o
            înscriere separată pentru fiecare temă și va fi aleasă o singură
            propunere din cele trimise (dacă sunteți autor unic).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel10'}
        onChange={handleChange('panel10')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10a-content"
          id="panel10a-header"
        >
          <Typography component="p" variant="h6">
            Pot trimite și alte tipuri de contribuții, atelier, poster?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            La acest moment apelul este deschis doar pentru contribuții de tip
            prezentări.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
