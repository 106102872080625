import React from 'react'

import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: '',
    }
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: 'SUCCESS' })
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }

  render() {
    const { status } = this.state

    const classes = {
      root: {
        backgroundColor: 'transparent',
        // boxShadow: 'none',
      },
    }
    return (
      <Paper elevation={0} className="contactForm" style={classes.root}>
        <Typography
          variant="h4"
          gutterBottom
          style={{
            color: 'white',
            fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
          }}
        >
          Contactează-ne!
        </Typography>
        <form
          onSubmit={this.submitForm}
          action="https://formspree.io/xrggboqa"
          method="POST"
        >
          <input name="Tip" type="hidden" value="Formular de contact CNCES" />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextField
                id="name"
                name="Name"
                type="text"
                label="Nume și prenume"
                margin="dense"
                variant="outlined"
                required={true}
                fullWidth={true}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                InputProps={{
                  style: { color: 'white' },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                id="email"
                name="Email"
                type="email"
                label="Adresă email"
                margin="dense"
                variant="outlined"
                required={true}
                fullWidth={true}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                InputProps={{
                  style: { color: 'white' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="Mesaj"
                label="Mesaj"
                multiline
                rows="4"
                margin="dense"
                variant="outlined"
                required={true}
                fullWidth={true}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                InputProps={{
                  style: {
                    color: 'white',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {status === 'SUCCESS' ? (
                <Paper
                  elevation={4}
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    padding: 24,
                    textAlign: 'center',
                  }}
                >
                  Mulțumim pentru mesaj!
                </Paper>
              ) : (
                <Button
                  id="submit_button"
                  variant="contained"
                  color="primary"
                  size="large"
                  // fullWidth={isWidthDown('md', this.props.width)}
                  type="submit"
                  // style={
                  //   isWidthDown('md', this.props.width) ? { padding: 16 } : {}
                  // }
                >
                  Trimite mesajul
                </Button>
              )}
              {status === 'ERROR' && (
                <Paper
                  elevation={4}
                  style={{
                    backgroundColor: '#B22222',
                    color: 'white',
                    padding: 24,
                    textAlign: 'center',
                  }}
                >
                  Ooops! A apărut o eroare.
                </Paper>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    )
  }
}
